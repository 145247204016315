<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
              อัพโหลดรูปโปรไฟล์
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form">
              <v-file-input
                v-model="image"
                ref="image"
                :rules="rules"
                small-chips
                color="secondary"
                accept=".png, .jpg, .jpeg"
                prepend-icon="mdi-camera"
                required
              ></v-file-input>
            </v-form>
            <br />
            <v-progress-linear
              :active="uploading"
              v-model="uploadPercentage"
              height="25"
            >
              <strong> {{ uploadPercentage }}% </strong>
            </v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              class="body-2 font-weight-bold"
              outlined
              @click="upload()"
              >Upload</v-btn
            >
            <v-btn
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="cancel()"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "UploadProfileAvatarDialog",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      image: null,
      uploadPhotoResponse: null,
      uploading: false,
      uploadPercentage: 0,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      rules: [
        (value) => !!value || "Photo is required",
        (value) =>
          !value ||
          value.size < 10000000 ||
          "Photo size should be less than 10 MB!",
      ],
    };
  },
  methods: {
    open: function() {
      this.dialog = true;
      // console.log(albumIndex);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    upload() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("upload", this.image, this.image.name);
        // console.log(formData);
        axios
          .post("/user/profile/avatar/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: function(progressEvent) {
              this.uploading = true;
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
          .then((response) => {
            this.uploadAvatarResponse = response.data;
            if (this.uploadAvatarResponse.status === "success") {
              this.$store
                .dispatch("user/changeAvatar", this.uploadAvatarResponse.data.avatar)
                .then(() => {
                  this.uploading = false;
                  this.loading = false;
                  this.$refs.form.reset();
                  this.resolve(true);
                  this.dialog = false;
                })
                // eslint-disable-next-line no-console
                .catch((error) => console.log(error));
            }
          })
          .catch(function() {});
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
