<template>
  <v-container>
    <v-row wrap no-gutters>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" grow pa-1>
        <UserProfileCard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserProfileCard from "./UserProfileCard.vue";


export default {
  name: "CustomerShow",
  components: {
    UserProfileCard,
  },
  props: {

  },
  data() {
    return {

    };
  },
  created() {
  },
  methods: {
  },
};
</script>
