<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            เปลี่ยนรหัสผ่าน.
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="currentPassword"
              :rules="currentPasswordRules"
              :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showCurrentPassword ? 'text' : 'password'"
              color="secondary"
              hint="Current password"
              label="Current password"
              @click:append="showCurrentPassword = !showCurrentPassword"
              clearable
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              :rules="newPasswordRules"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              color="secondary"
              hint="New password"
              label="New password"
              @click:append="showNewPassword = !showNewPassword"
              clearable
            ></v-text-field>
            <v-progress-linear
              :color="newPasswordScore.color"
              :value="newPasswordScore.value"
            ></v-progress-linear>
            <v-text-field
              v-model="confirmNewPassword"
              :rules="confirmNewPasswordRules"
              :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmNewPassword ? 'text' : 'password'"
              color="secondary"
              hint="Confirm New password"
              label="Confirm New password"
              @click:append="showConfirmNewPassword = !showConfirmNewPassword"
              clearable
            ></v-text-field>
            <v-progress-linear
              :color="confirmNewPasswordScore.color"
              :value="confirmNewPasswordScore.value"
            ></v-progress-linear>
          </v-form>
          <v-flex>
            <v-alert v-if="isChangePasswordError" type="error" dismissible>
              {{ changePasswordErrorMessage }}
            </v-alert>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            @click="save()"
            >save</v-btn
          >
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cancel()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
const zxcvbn = require('zxcvbn');

export default {
  name: "EditUserFullNameDialog",
  props: {},
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      isChangePasswordError: false,
      changePasswordErrorMessage: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      // newPasswordScore: null,
      // confirmNewPasswordScore: null,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      currentPasswordRules: [(value) => !!value || "Old password is required"],
      newPasswordRules: [
        (value) => !!value || "New password is required",
        // (value) => value.length >= 8 || "Use 8 characters or more for your password",
        (value) => this.calZxcvn(value) >= 3 ||
          "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",
      ],
      confirmNewPasswordRules: [
        (value) => !!value || "Confirm New password is required",
        (value) => value === this.newPassword || "Passwords do not match",
        // (value) => value.length >= 8 || "Use 8 characters or more for your password",
        (value) => this.calZxcvn(value) >= 3 ||
          "Please choose a stronger password. Try a mix of letters, numbers, and symbols.",
      ],
    };
  },
  computed: {
    newPasswordScore() { 
      if(this.newPassword !== null) {
        const result = zxcvbn(this.newPassword);
        return this.calPasswordScore(result.score);
      } else {
        return this.calPasswordScore(0);
      }
    },
    confirmNewPasswordScore() {
      if(this.confirmNewPassword !== null) {
        const result = zxcvbn(this.confirmNewPassword);
        return this.calPasswordScore(result.score);
      } else {
        return this.calPasswordScore(0);
      }
    },
  },
  methods: {
    open: function() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        axios
          .post("/user/profile/change-password", {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword,
          })
          .then((response) => {
            this.changePasswordResponse = response.data;
            if (this.changePasswordResponse.status === "success") {
              this.loading = false;
              this.resolve(true);
              this.$refs.form.reset();
              this.isChangePasswordError = false;
              this.dialog = false;
              this.$emit("password-changed");
            }
          })
          .catch((error) => {
            this.isChangePasswordError = true;
            this.changePasswordErrorMessage = error.response.data.message;
          });
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.isChangePasswordError = false;
      this.resolve(false);
      this.dialog = false;
    },
    calZxcvn(password){
      if(password !== null){
        return zxcvbn(password).score;
      } else{
        return 0
      }
    },
    calPasswordScore(zxcvbnResult) {
      switch (zxcvbnResult) {
        case 4:
          return {
            color: "light-blue",
            value: 100,
          };
        case 3:
          return {
            color: "light-green",
            value: 75,
          };
        case 2:
          return {
            color: "yellow",
            value: 50,
          };
        case 1:
          return {
            color: "orange",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
  },
};
</script>
