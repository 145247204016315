<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
            แก้ไขชื่อ.
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="fullname"
              :rules="rules"
              color="secondary"
              counter="25"
              hint="ชื่อ"
              label="ชื่อ"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            @click="save()"
            >save</v-btn
          >
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="cancel()"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import axios from "axios";

export default {
  name: "EditUserFullNameDialog",
  props: {},
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      fullname: null,
      options: {
        color: "primary",
        width: 400,
        zIndex: 200,
      },
      rules: [(value) => !!value || "Name is required"],
    };
  },
  computed: {},
  methods: {
    open: function() {
      this.dialog = true;
      this.fullname = this.$store.getters['user/userFullName'];
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch("user/changeFullName", this.fullname)
          .then(() => {
            this.$refs.form.reset();
            this.resolve(true);
            this.dialog = false;
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.log(error));
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
